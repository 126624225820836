import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-scroll";
import { useLocation } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

const NavBar = () => {
  const location = useLocation();
  const [nav, setNav] = useState(false);

  const links = [
    {
      id: 1,
      link: "home",
    },
    {
      id: 2,
      link: "about",
    },
    {
      id: 3,
      link: "certifications",
    },
    {
      id: 4,
      link: "portfolio",
    },
    {
      id: 5,
      link: "experience",
    },
    {
      id: 6,
      link: "contact",
    },
  ];

  return (
    <div className="flex justify-between z-20 items-center w-full h-20 px-4 text-white bg-black fixed select-none">
      <div>
        <h1 className="lg:text-5xl text-3xl font-signature ml-2">
          Michael Ilkanayev
        </h1>
      </div>

      {location.pathname === "/projectdetails" ? (
        <ul className="flex">
          <li className="text-xl px-4 cursor-pointer capitalize font-medium text-gray-500 hover:text-gray-300 hover:scale-105 duration-200">
            <RouterLink to={"/"}>Home</RouterLink>
          </li>
        </ul>
      ) : (
        <ul className="hidden md:flex">
          {links.map(({ id, link }) => (
            <li
              key={id}
              className="px-4 cursor-pointer capitalize font-medium text-gray-500 hover:text-gray-300 hover:scale-105 duration-200"
            >
              <Link to={link} href="" smooth duration={500}>
                {link}
              </Link>
            </li>
          ))}
        </ul>
      )}

      {location.pathname !== "/projectdetails" && (
        <div
          onClick={() => setNav(!nav)}
          className="cursor-pointer pr-4 z-10 text-gray-500 md:hidden"
        >
          {nav ? <FaTimes size={30} /> : <FaBars size={30} />}
        </div>
      )}

      {nav && (
        <ul
          className="flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen 
      bg-gradient-to-b from-black to-gray-800 text-gray-500"
        >
          {links.map(({ id, link }) => (
            <li
              key={id}
              className="px-4 cursor-pointer capitalize py-6 text-4xl"
            >
              <Link
                onClick={() => setNav(!nav)}
                to={link}
                smooth
                duration={700}
              >
                {link}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default NavBar;
